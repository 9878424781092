*{
  margin: 0;padding: 0;box-sizing: border-box;
}
@font-face {
  font-family: 'CustomFont';
  src: url('./Assets/Calibri\ Regular.ttf') format('truetype'); /* Adjust path and format as needed */
}
.home{
  background-color: #FCFBF8;
}
.navbar{
  position: fixed;
  top: 0;
  width: 100%;
  height: 85px;
  background-color: rgba(185, 18, 26, 0.818);
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}
/* .navbar img{
  height: 8px;

} */
.navbar ul{
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.navbar ul li{
  list-style: none;
  font-family: "CustomFont", sans-serif;
color: white;
  font-size: 18px;
  font-weight: 500;
}
.navbar button{
  height: 51px;
  width: 143px;
  background: none;
  border-radius: 30px;
  border: 1px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
   font-family: "CustomFont", sans-serif;

  font-size: 14px;
  font-weight: 600;

}
.about-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 50px;
}
.about-section-2{
  width: 40%;
  text-align: center;
  padding: 100px 30px;
}
.about-section-2 h4{
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 400;
}
.about-section-2 h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
}
.about-section-2 h2 i{
  color: rgba(185, 18, 27, 1);
}
.about-section-2 p{
   font-family: "CustomFont", sans-serif;

  font-size: 18px;
  font-weight: 300;
  color: rgba(14, 21, 14, 1);
}
.about-section-2 button{
  padding: 14px 25px 13px 25px;
  border-radius: 30px;
  background-color: rgba(185, 18, 27, 1);
  border: 1px solid rgba(185, 18, 27, 1);
   font-family: "CustomFont", sans-serif;

  font-size: 14px;
  font-weight: 400;
  color: white;
}
.sercices-card-heading{
  text-align: center;
}
.sercices-card-heading p{
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 400;
}
.sercices-card-heading h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
}
.sercices-card-heading h2 i{
  color: rgba(185, 18, 27, 1);
}
.sercices-card-div{
  padding: 50px;
}
.sercices-cards{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  padding-top: 50px;
  flex-wrap: wrap;
}
.sercices-card{
  height: auto;
  width: 370px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
padding: 11px 11px 31px 11px;
}
.sercices-card h3{
  font-family: "Playfair Display", serif;
  font-size: 26px;
  font-weight: 400;
  text-align: left;
  margin-top: 30px;

}
.sercices-card p{
   font-family: "CustomFont", sans-serif;

  font-size: 15px;
  font-weight: 300;
  text-align: left;
  margin-top: 20px;

}
.home-slider{
  display: flex;
  flex-direction: row;
  padding-left: 50px;
  padding-right: 50px;
  background-color: rgba(245, 240, 232, 1);
}
.home-slider-left{
  width: 50%;
}

.home-slider-right{
  width: 50%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}

.home-slider-right h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
}
.home-slider-right h2 i{
  color: rgba(185, 18, 27, 1);
}
.slide-divs{
  display: flex;
  flex-direction: row;
 gap: 50px;
}
.slide-div{
  width: 250px;
}
.slide-div h3{
  font-family: "Playfair Display", serif;
  font-size: 26px;
  font-weight: 400;
  margin-top: 15px;
}
.slide-div p{
   font-family: "CustomFont", sans-serif;

  font-size: 15px;
  font-weight: 300;
  margin-top: 15px;

}
.about-thailand{
  display: flex;
  flex-direction: row;
  padding: 50px;
}
.about-thailand-left{
  width: 100%;
}
.about-thailand-right{
  width: 50%;
  padding-top: 250px;
}
.about-thailand-left p{
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 400;
}
.about-thailand-left h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
}
.about-thailand-left h2 i{
  color: rgba(185, 18, 27, 1);
}
.about-thailand-left  h3{
   font-family: "CustomFont", sans-serif;

  font-size: 18px;
  font-weight: 300;
}
.customer-section{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.customers{
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
  gap: 100px;
  padding: 100px;
  overflow-x: auto;

}
.customers-div{
  height: 200px;
  width: 200px;
  border: 1px solid rgba(185, 18, 27, 0.4);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.customers-div h2{
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 500;
  color: rgba(185, 18, 27, 1);
}
.customers-div p{
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: rgba(14, 14, 14, 1);
}
.thai{
  height: 100vh;
  background-image: url(./Assets/1398.jpg);
  background-size: cover;
 padding-top:85vh;
 background-position: center;

}
.thai p{
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: white;
}
.thai-text{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}
.bangkok-section-div{
  padding: 50px 100px;
 
}
.bangkok-section{
  padding: 50px;
  background-size: cover;
border-radius: 20px;
  background-image: url(./Assets/1020.jpg);
}
.bk-slides{
  padding: 32.81px 33.23px 42.81px 40px;
  width: 50%;
  background-color: white;
  border-radius: 10px;
  
}
.bk-slides img{
  width: 100%;
}
.bk-slides h2{
  font-family: "Playfair Display", serif;
  font-size: 38px;
  font-weight: 400;
}
.bk-slides h3{
   font-family: "CustomFont", sans-serif;

  font-size: 16px;
  font-weight: 300;
}
.bk-slides p{
  font-family: "Playfair Display", serif;
  font-size: 17px;
  font-weight: 400;
}
.teams-section{
  display: flex;
  justify-content: center;
  align-items: center;
}
.team-heading{
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}
.team-heading h3{
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 400;
}

.team-heading h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 15px;
}
.team-heading h2 i{
  color: rgba(185, 18, 27, 1);
}
.team-heading p{
   font-family: "CustomFont", sans-serif;

  font-size: 18px;
  font-weight: 300;
}
.team-div{

}
.team-card{
 
  height: 360px;
  width: 262px;
  border-radius: 150px;
  background-color: rgba(245, 240, 232, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  
  gap: 10px;
}
.team-hover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 150px;


}

.team-card:hover .team-hover {
  opacity: 1;
}
.team-card h2{
  font-family: "Playfair Display", serif;
  font-size: 17px;
  font-weight: 600;
}
  .team-card h3{
  font-family: "Playfair Display", serif;
  font-size: 17px;
  font-weight: 600;
}
.team-card li{
  list-style: none;
  display: flex;
  gap: 10px;
  color: #D16D56;
  font-size: 20px;
}
.none2{
  text-decoration: none;
  color: #D16D56;

}
.team-div{
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
  gap: 50px;
  padding: 50px;
  overflow-x: auto;
}


.thai-slid {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.thai-slid img {
  flex: 0 0 auto;
 
  margin-right: 10px; /* Space between images */
}
.contact-from{
  height: auto;
  background-image: url(./Assets/pexels-nextvoyage-910012.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.contact-from-div{
  width: 45%;
  height: 80%;
  background-color: rgba(185, 18, 27, 1);
  border-radius: 10px;
  color: white;
  padding: 50px;
}
.contact-from-div h3{
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
}
.contact-from-div h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
}
.contact-from-div label{
   font-family: "CustomFont", sans-serif;

  font-size: 16px;
  font-weight: 700;
}
.contact-from-div input{
  width: 100%;
  height: 47px;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.contact-from-div button{
  width: 100%;
  height: 48px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(185, 18, 27, 1);
  border-radius: 30px;
   font-family: "CustomFont", sans-serif;

  font-size: 15px;
  font-weight: 700;
  color: rgba(185, 18, 27, 1);
}
.test-div{
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonials-cards{
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
  gap: 20px;
  overflow-x: auto;
  width: 100%;
}
.testimonials{
  padding-top: 50px;
  padding-bottom: 50px;
}
.testimonials p{
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;

}
.testimonials h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
  text-align: center;

}
.testimonials h2 i{
  color: rgba(185, 18, 27, 1);


}
.testimonials-card{
height: auto;
width: 355px;
background-color: rgba(255, 255, 255, 1);
border-radius: 12px;
flex: 0 0 auto;

}
.testimonials-card{
  padding: 20px;
}
.testimonials-card i{
  color: gold;
}
.testimonials-card p{
  text-align: left;
   font-family: "CustomFont", sans-serif;

  font-size: 16px;
  font-weight: 300;
}
.stars{
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.client-img{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}
.client-img img{
  border-radius: 50%;
}

.partners{
  padding: 50px;
  background-color: rgba(245, 240, 232, 1);

}
.partners p{
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;

}
.partners h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
  text-align: center;
}
.partners h2 i{
  color: rgba(185, 18, 27, 1);

}
.partners-slide {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.partners-slide img {
  width: 100px; /* Adjust the size as needed */
  height: auto;
}

.marquee {
  display: flex;
  gap: 50px;
  animation: marquee 25s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.getintouch{
  text-align: center;
  padding: 50px;
}
.getintouch h1{
  font-family: "Playfair Display", serif;
  font-size: 90px;
  font-weight: 400;
}
.footer{
  /* padding: 10px; */
}
.footer-div{

/* padding: 20px; */
gap: 30px;
background-color: rgba(185, 18, 27, 1);
color: rgb(255, 255, 255);
padding-bottom: 20px;
padding-top: 20px;
padding-left: 50px;
padding-right: 50px;

}
.footer-div li{
  list-style: none;
  color: rgb(255, 255, 255);
  font-weight: 400;
}
.footer-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.foot-ul{
display: flex;
flex-direction: column;
gap: 55px;
text-align: center;
}
.foot-ul li{
  font-family: "Inter", sans-serif;
  font-size: 18px;

  font-weight: 400;

}
.foot-ul ul{
  display: flex;
  flex-direction: row;
  gap: 50px;

}
.foot-ul p{
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.foot-ul-last{
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 300;
}
.foot-ul-last input{
  background: none;
  border: none;
  /* border-bottom: 1px solid white; */
  padding-bottom: 10px;
  width: 200px;

}
.foot-ul-last input::placeholder{
  color: rgb(255, 255, 255);
}
.footeript{
  display: flex;
  justify-content: center;
  /* align-items: center; */
  border-bottom: 1px solid rgb(255, 255, 255);
  padding-bottom: 5px;
}
.bottom-footer{
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;

}
.bottom-footer p{
   font-family: "CustomFont", sans-serif;


  font-size: 15px;
  font-weight: 400;
}
.bottom-footer p span{
  font-weight: 600;
}
.about-banner{
  padding: 50px 50px;

  background-image: url(./Assets//backgroun.svg);
  display: flex;
  flex-direction: row;
padding-top: 100px;

}
.about-banner-left{
  width: 60%;
  padding-top: 50px;
}
.about-banner-left p{
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 400;
}
.about-banner-left h2{
  font-family: "Playfair Display", serif;
  font-size: 66px;
  font-weight: 400;
}
.about-banner-left h2 i{
  color: rgba(185, 18, 27, 1);

}
.about-banner-left h3{
   font-family: "CustomFont", sans-serif;

  font-size: 18px;
  font-weight: 300;
}
.about-banner-right{
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.whoweare{
padding: 50px;
}
.whoweare p{

}
.whoweare h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
}
.whoweare h2 i{
  color: rgba(185, 18, 27, 1);

}

.vission{
  display: flex;
  flex-direction: row;

}
.vission-right{
  padding: 100px;
}
.vission-right img{
  width: 80%;
}
.vission-right p{
   font-family: "CustomFont", sans-serif;

  font-size: 18px;
  font-weight: 300;
}
.vission-right h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
}
.vission-right h2 i{
  color: rgba(185, 18, 27, 1);

}
.vission-right2{
  padding: 20px;
  /* padding-top: 50px; */
}
.Ingenuity{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  padding-left: 30px;
  padding-right: 30px;
  background-size: cover;
  background-position: center;
  background-image: url(./Assets/jonny-clow-bl5T3frR9pc-unsplash.jpg);
}
.Ingenuity p{
   font-family: "CustomFont", sans-serif;

  font-size: 18px;
  font-weight: 300;
  color: white;
  text-align: center;
}
.Ingenuity h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
  text-align: center;
  color: white;

}
.bi-play-circle{
  font-size: 100px;
  color: white;

}
.client-count-card{
  flex: 0 0 auto;
  height: 200px;
  width: 200px;
  border: 1px solid rgba(185, 18, 27, 0.4);
  background-color: rgba(245, 240, 232, 1);
  text-align: center;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.client-count-div{
  display: flex;
  justify-content: center;
  align-items: center;
}
.client-count-card h2{
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 500;
  color: rgba(185, 18, 27, 1);
  margin-bottom: 10px;
}
.client-count-card p{
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: rgba(14, 14, 14, 1);
}
.client-count{
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  gap: 20px;
  padding: 50px;
  overflow-x: auto;
}
.journey{
  padding-left: 30px;
  padding-right: 30px;
}
.journey h3{
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;

}
.journey h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
  text-align: center;


}
.journey h2 i{
  color: rgba(185, 18, 27, 1);

}
.Journeyyear{
  display: flex;
  flex-direction: row !important;
  padding: 50px;
}
.Journeyyear-left{
  width: 50%;
  text-align: left !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Journeyyear-right{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Journeyyear-right img{
  width: 80%;
}
.Journeyyear-left h3{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
  color: rgba(185, 18, 27, 1);
  text-align: left;

}
.Journeyyear-left h2{
  font-family: "Playfair Display", serif;
  font-size: 31px;
  font-weight: 400;
  text-align: left;

}
.Journeyyear-left p{
   font-family: "CustomFont", sans-serif;

  font-size: 18px;
  font-weight: 300;
}
.contact{
  height: 80vh;
  background-image: url(./Assets/backgroun.svg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}
.contact p{
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 400;
}
.contact h2{
  font-family: "Playfair Display", serif;
  font-size: 66px;
  font-weight: 400;
}
.contact h3{
   font-family: "CustomFont", sans-serif;

  font-size: 18px;
  font-weight: 300;
}
.booking{
  display: flex;
  flex-direction: row;
  padding: 50px;
}
.booking-left{
  width: 60%;
}
.booking-right{
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.booking-left h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
}
.booking-left p{
   font-family: "CustomFont", sans-serif;

  font-size: 18px;
  font-weight: 300;
}
.booking-left img{
  width: 100%;
}
.msg-box{
  height: 90%;
  width: 90%;
  background-color: white;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 50px;
}
.msg-box h2{
  text-align: center;
  font-family: "Playfair Display", serif;
  font-size: 31px;
  font-weight: 400;
}
.msg-box input{
  width: 100%;
  height: 46px;
  border: 1px solid rgba(245, 240, 232, 1);
  border-radius: 6px;
  padding-left: 20px;
}
.msg-box textarea{
  width: 100%;
  border: 1px solid rgba(245, 240, 232, 1);
  border-radius: 6px;
  padding: 20px;
}
.msg-box button{
  height: 44px;
  width: 100%;
  background-color: rgba(185, 18, 27, 1);
  border-radius: 31px;
  border: none;
  color: white;
   font-family: "CustomFont", sans-serif;

  font-size: 15px;
  font-weight: 300;
}
.LOCATION{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
}
.LOCATION h2{
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 400;
  text-align: center;

}
.LOCATION h2 i{
  color: rgba(185, 18, 27, 1);

}
.LOCATION p{
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;

}
.LOCATION-div{
  width: 80%;
  background-color: white;
  padding: 30px;

}
.LOCATION-div img{
  width: 100%;
}
.discover{
  background-image: url(./Assets/waranont-joe--bULSZsK84U-unsplash.jpg);
  height: 80vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-position: center;
  
}
.discover h2{
  font-size: 66px;
  font-family: "Playfair Display", serif;
  text-align: center;
  font-weight: 400;

}
.map{
  width: 100%;
  height: 363px;
}
.none{
  text-decoration: none;
  color: black;
}
.bi-list{
  display: none;
  font-size: 25px;
  color: white;
}
@media(max-width:855px){
  nav ul{
    display: none;
    }
    .bi-list{
      display: block;
    }
    .navbar ul{
      display: none;
    }
    .navbar button{
      display: none;

    }
    .navbar{
      padding-left: 30px;
      padding-right: 30px;
    }

}
.bi-list {
  cursor: pointer;
}

/* Styles for the toggleable div */
.toggleDiv {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color: #000000d4;
  border: 1px solid #dddddd;
  border-radius: 50px;
  color: white;
  text-align: center;
margin-top: 150px;
padding-top: 50px;

}
.toggleDiv ul li{
  font-family: "Manrope", sans-serif;
  color: white;

  font-weight: 700;
  font-size: 16px;
  list-style: none;
  margin-bottom: 25px;
}
.toggleDiv-ops{
  width: 100%;
  height: 100vh;
  background-color: #000000c1;
  position: fixed;
z-index: 100;
  top: 90px;
}
@media(max-width:1120px){

  .about-section-1{
    display: none;
  }
  .about-section-2{
  width: 60%;

  }
  .about-section-3{
    width: 40%;
  }
  .home-slider-right h2{
    font-size: 40px;
  }
  .bk-slides{
    width: 80%;
    
  }
  .bangkok-section{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media(max-width:1060px){
  .home-slider-left img{
    width: 80%;
height: 100%;
  }
  .home-slider-right{
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .home-slider-left{
    width: 40%;

  }
  .contact-from-div{
    width: 70%;
  }
  
  .home-slider-right{
    width: 60%;
  }
}
@media(max-width:900px){
  .about-section-2{
    width: 70%;
  
    }
    .about-section-3{
      width: 30%;
    }
    .about-section-3 img{
      width: 90%;
    }
}
@media(max-width:850px){
  .home-slider-left{
    display: none;
  }
  .home-slider-right{
    width: 100%;
    
  }
  .home-slider{
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media(max-width:768px){
  .about-section{
    flex-direction: column;
    gap: 1px;
  }
  .about-section-2{
    width: 100%;
  
    }
    .about-section-3{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .about-section-3 img{
      width:50%;
    }
    .slide-divs{
      flex-direction: column;
    }
    .slide-div{
      width: 100%;
    }
    .home-slider-right h2{
  font-size: 30px;

    }
    .about-thailand{
      flex-direction: column;
    }
    .about-thailand-left{
      width: 100%;
    }
    .about-thailand-right{
      width: 100%;
      padding-top: 20px;
    }
    .about-thailand-left h2{
      font-size: 30px;
    }
    .bangkok-section-div{
      padding: 50px 30px;
     
    }
    .bangkok-section{
      padding: 50px 20px;
    }
    .bk-slides{
      width: 100%;
    }
.sercices-card-heading h2,
.about-section-2 h2 
{
  font-size: 30px;

    }
    .about-section-2 h4 ,.sercices-card-heading p{
  font-size: 18px;
    }
    .home-slider-right h2{
      font-size: 25px;
    }

}
.sercices-cards-div{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media(max-width:1250px){

  .sercices-cards{
    overflow-x: auto;
    justify-content: baseline;
  }
}
@media(max-width:768px){
.sercices-cards{
  padding-top: 20px;

}
.sercices-card-div{
  padding: 30px;
  padding-top: 50px;
  padding-bottom: 50px;

}
.sercices-card{
  width: 45%;

}
.sercices-card img{
  width: 100%;
}
.thai{
  height: 80vh;
  padding-top:65vh;
}
.contact-from-div{
  width: 90%;
}
.getintouch h1{
  font-size: 40px;

}
.footer-div{
  display: flex;
  flex-wrap: wrap;
}
.footer{
  padding: 30px;
}
.foot-ul-last{
  display: none;
}
}


@media(max-width:560px){

.sercices-card{
  width: 100%;

}
.customers{
  gap: 30px;

}
}
@media(max-width:550px){
  .about-thailand{
  padding: 50px 30px;
  }
  .customers{
    flex-direction: column;
  }
  .customers-div{
    height: 200px;
    width: 200px;
  }
  .customers-div h2{
  font-size: 70px;

  }
.customers-div p{
font-size: 15px;
}
.team-heading h2{
  font-size: 35px;
}
.contact-from-div h2{
  font-size: 30px;

}
.contact-from-div h3{
  font-size: 15px;

}
.testimonials h2{
  font-size: 30px;

}
.testimonials p{
  font-size: 15px;

}
.partners h2{
  font-size: 30px;

}
.partners p{
  font-size: 15px;

}
}
@media(max-width:600px){
  .foot-ul ul{
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .foot-ul p{
  font-size: 15px;

  }
  .footer{
    padding: 20px;
  }
  .footer-div{
    padding: 1px;
    padding-top: 20px;
  }
}




@media(max-width:1200px){
  .about-banner-left h2{
    font-size: 48px;
  }
  .about-banner{
    gap: 50px;
  }
}
@media(max-width:1100px){
  .vission-right{
    padding: 50px;
  }
}
@media(max-width: 950px){
.about-banner-right img{
  width: 80%;

}
}
@media(max-width: 950px){
.about-banner{
  flex-direction: column;
}
.about-banner-left{
  width: 100%;
}
.about-banner-right{
  width: 100%;
}
.about-banner-right img{
  width: 60%;

}
}
@media(max-width:600px){
  .about-banner-right img{
    width: 80%;
  
  }
  .about-banner{
    padding: 50px 30px;
  }
  .about-banner-left h2{
    font-size: 30px;
  }
.about-banner-left p{
  font-size: 20px;
}
}
@media(max-width:1000px){
  .vission{
    flex-direction: column;
    gap: 30px;
  }
  .vission-left{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .vission-left img{
    width: 80%;
  }
  .vission-right{
    padding: 0;
    width: 100%;
 
  }
}
@media(max-width: 768px){
  .vission-right h2{
  font-size: 30px;

  }
  .whoweare h2{
  font-size: 30px;

  }
  .whoweare{
    padding: 30px;
    }
    .Ingenuity h2{
      font-size: 30px;
    }
    .Ingenuity p{
      font-size: 15px;
    }
}
@media(max-width: 550px){
  .bi-play-circle{
    font-size: 80px;
  }
  .Ingenuity{
    height: 60vh;
  }
}
@media(max-width: 768px){
  .journey h2{
    font-size: 30px;
  }
  .journey h3{
    font-size: 20px;
    margin-top: 50px;
  }
  .Journeyyear{
    flex-direction: column !important;
  padding: 0px;
  gap: 30px;

  }
  .Journeyyear-left{
    width: 100%;
  }
  .Journeyyear-right{
    width: 100%;
  }
  .contact{
    height: 50vh;
  }
  .contact p{
  font-size: 20px;

  }
  .contact h2{
    font-size: 35px;
  }
  .contact h3{
    font-size: 15px;
  }
}
@media(max-width: 1100px){
  .booking-left{
    width: 50%;
  }
  .booking-right{
    width: 50%;
  }
}
@media(max-width: 900px){
.booking{
  flex-direction: column;
  gap: 50px;
}
.booking-left{
  width: 100%;
}
.booking-right{
  width: 100%;
}
}
@media(max-width: 768px){
  .booking{
    padding: 50px 30px;
  }
  .booking-left img{
    width: 100%;
  }
  .booking-left h2{
    font-size: 30px;
  }
  .booking-left p{
    font-size: 15px;
  }
  .msg-box{
    width: 100%;
    padding: 30px;
  }
  .msg-box h2{
    font-size: 25px;
  }
  .LOCATION h2{
    font-size: 30px;
  }
  .LOCATION p{
    font-size: 15px;
  }
  .LOCATION-div{
    width: 100%;
  }
  .discover{
  height: 50vh;

  }
.client-count-card{
height: 200px;
width: 200px;
}
.client-count-card h2{
  font-size: 80px;
}
.client-count{
  padding: 50px 30px;
}
}
@media(max-width: 550px){
  .msg-box{
    width: 100%;
    padding: 30px 20px;
  }
.LOCATION{
  padding: 10px;
}
.LOCATION-div{
  padding: 20px;
}
.discover h2{
  font-size: 40px;
}
}
.thai-slid{
  height: 300px;
}
.home-banner{
  background-image: url(./Assets/aaaaaaaa.jpg);
  height: 100vh;
  background-size: cover;
background-position: bottom;
}
.home-banner2{
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.488);
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
  text-align: center;
  color: white;
  padding-left: 300px;
  padding-right: 300px;
}
.home-banner2 p{
  font-family: "Playfair Display", serif;
  font-size: 16px;
  font-weight: 400;
}
.home-banner2 h2{
  font-family: "Playfair Display", serif;
  font-size: 66px;
  font-weight: 400;
}
.home-banner2 h3{
   font-family: "CustomFont", sans-serif;

  font-size: 18px;
  font-weight: 400;
}
.home-btns{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.home-btns1{
  padding: 14px 25px 13px 25px;
  background-color: rgba(185, 18, 27, 1);
  border-radius: 30px;
  color: white;
  border: none;
   font-family: "CustomFont", sans-serif;

  font-size: 14px;
  font-weight: 400;
}
.home-btns2{
  background: none;
  border: none;
   font-family: "CustomFont", sans-serif;

  font-size: 14px;
  font-weight: 400;
  color: white;

}
.logo{
  height: 55px !important; 
}
@media(max-width:1120px){
  .home-banner2{
    padding-left: 100px;
    padding-right: 100px;
  }
}


@media(max-width:768px){
  .home-banner2{
    padding-left: 50px;
    padding-right: 50px;
  }
  .home-banner2 h2{
    font-size: 40px;
  }
.home-banner2 p{
font-size: 14px;
}
.home-banner2 h3{
font-size: 15px;

}
}
@media(max-width:550px){
  .home-banner2{
    padding-left: 20px;
    padding-right: 20px;
  }
  .home-banner2 h2{
    font-size: 35px;
  }
  .home-banner2 h3 , .home-banner2 p{
    font-size: 12px;
    
    }
}
.social-media-icons{
  display: flex;
  flex-direction: row;
  gap: 20px;

}
.social-media-icons i{
  background-color: white;
  color: rgba(185, 18, 27, 1);
  padding: 8px;
  border-radius: 10px;
  font-size: 20px;
}
.office-address{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.office-address p{
   font-family: "CustomFont", sans-serif;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  gap: 10px;

}
.office-address i{
  color: rgba(185, 18, 27, 1);
  margin-top: -5px;
}
.LOCATION-div h2{
  font-family: "Playfair Display", serif;
  font-size: 31px;
  font-weight: 400;
  text-align: start ;
  margin-bottom: 10px;
  margin-top: 10px;

}
@media(max-width:768px){
.office-address{
  flex-direction: column;
  align-items: start;
}
.footer-div{
display: flex;
justify-content: center;
align-items: center;
padding: 20px;
}
.footer-div li{
  display: flex;
justify-content: center;
align-items: center;
}
}